<template>
  <section class="w-full my-3">
    <div class="intro-y flex flex-col md:flex-row items-center">
      <h1 class="text-lg font-bold truncate mr-5">Información de las Inversiones</h1>
      <div class="ml-auto flex flex-col md:flex-row items-center text-theme-1 dark:text-theme-10 w-full md:w-auto">
        <Button icon="pi pi-info-circle" class="p-button-rounded p-button-info p-button-text" v-tooltip.left="'Información de las Inversiones'"/>
      </div>
    </div>
  </section>

  <!-- Componente de la fase -->
  <div v-for="(stage, index) in infoStages" :key="index">
    <stage-item
      :numberStage="stage.stage"
      :typeInvestmentStage="stage.typeInvestment"
      :totalValueStage="stage.totalValue"
      :investmentDiscountStage="stage.investmentDiscount"
      :investmentValueStage="stage.investmentValue"
      :totalPaymentStage="stage.totalPayment"
      :isVisible="stage.isVisible"
      @stageDetails="stageDetails"
    />
  </div>

</template>

<script>
import { defineAsyncComponent, onMounted, ref } from 'vue'
import listStages from '../../services/listStages'

export default {
  name: 'DashboardIndex',
  components: {
    stageItem: defineAsyncComponent(() => import('./components/stage'))
  },
  setup () {
    const infoStages = ref({})
    const viewData = ref(false)
    const isVisible = ref(false)

    /* Obtener el listado de las inversiones */
    const fetchStages = () => {
      return listStages({}).then(({ status, data }) => {
        infoStages.value = data.map(v => ({ ...v, isVisible: isVisible.value }))
        console.log(infoStages)
        if (infoStages.value.length > 0) {
          viewData.value = true
        } else {
          viewData.value = true
        }
      }).catch(err => {
        console.error(err.message)
      })
    }

    /* Detalle */
    const stageDetails = (numberStage, isVisible) => {
      const arrayTest = infoStages.value.filter((item) => {
        return item.stage === numberStage
      })

      arrayTest[0].isVisible = !isVisible
    }

    onMounted(() => {
      fetchStages()
    })

    return {
      infoStages,
      isVisible,
      viewData,
      stageDetails
    }
  }
}
</script>

<style scoped>

</style>
